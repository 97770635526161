angular.module('cpformplastApp')
  .controller('InfoDeliveryModalController', function ($scope, Notification, DataManager, delivery) {

    $scope.delivery = delivery;

    $scope.init = function() {
      if ($scope.delivery._id) {
        DataManager.fetchDelivery($scope.delivery._id).then(function(delivery){
          $scope.delivery = delivery
        },function(error){
          console.log(error);
        });
      }
    };
    $scope.init();
});
